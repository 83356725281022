<template>
    <div>
        <div class="row">
            <div class="col-12">
                <users-table @selection-change="onSelectionChange" />
                <div class="card">
                    <div class="card-body">
                        <div class="form-group col-12">
                            <label for="title">Title</label>
                            <div
                                :style="{
                                    color: isTooLong('title') ? 'red' : 'green'
                                }"
                            >
                                Characters: {{ getLength('title') }}/{{
                                    titleMaxCharacters
                                }}
                            </div>
                            <input
                                id="title"
                                v-model="message.title"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': $v.message.title.$error
                                }"
                                :maxlength="titleMaxCharacters"
                            />
                        </div>
                        <div class="form-group col-12">
                            <label for="text">Message</label>
                            <div
                                :style="{
                                    color: isTooLong('text') ? 'red' : 'green'
                                }"
                            >
                                Characters: {{ getLength('text') }}/{{
                                    textMaxCharacters
                                }}
                            </div>
                            <input
                                id="text"
                                v-model="message.text"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': $v.message.text.$error
                                }"
                                :maxlength="textMaxCharacters"
                            />
                        </div>
                        <div class="form-group col-12">
                            <label for="link">Link</label>
                            <input
                                id="link"
                                v-model="message.link"
                                type="link"
                                class="form-control"
                            />
                        </div>
                        <div
                            class="form-group col-12 d-flex justify-content-end"
                        >
                            <button
                                class="btn btn-info waves-effect waves-light"
                                data-animation="fadein"
                                data-plugin="custommodal"
                                data-overlaycolor="#38414a"
                                @click="sendNotificationsHandler"
                            >
                                Send Notifications
                            </button>
                        </div>
                    </div>
                    <!-- end card-body-->
                </div>
                <!-- end card-->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import config from '@config';
import UsersTable from './UsersTable';

export default {
    validations: {
        message: {
            title: { required },
            text: { required }
        }
    },

    page: {
        title: 'Push Notifications',
        meta: [{ name: 'description', content: config.description }]
    },

    components: {
        UsersTable
    },

    data() {
        return {
            ids: [],
            tokens: [],
            message: {
                title: '',
                text: '',
                link: ''
            },
            titleMaxCharacters: 65,
            textMaxCharacters: 240
        };
    },

    methods: {
        ...mapActions({
            sendNotifications: 'notifications/sendNotifications'
        }),

        async sendNotificationsHandler() {
            try {
                this.$v.message.$touch();

                if (this.$v.message.$invalid) {
                    return;
                }

                if (this.isTooLong('title' || this.isTooLong('text'))) {
                    return;
                }

                await this.sendNotifications({
                    ids: this.ids,
                    tokens: this.tokens,
                    message: this.message
                });

                this.$v.message.$reset();
                this.message.title = '';
                this.message.text = '';
                this.message.link = '';

                this.$bvToast.toast('Notifications has been send.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                const message =
                    (err.response && err.response.data) ||
                    'Something went wrong.';

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        onSelectionChange({ tokens, ids }) {
            this.ids = ids;
            this.tokens = tokens;
        },

        isTooLong(type) {
            const limit =
                type === 'title'
                    ? this.titleMaxCharacters
                    : this.textMaxCharacters;

            return this.message[type].length > limit;
        },

        getLength(type) {
            return this.message[type].length || 0;
        }
    }
};
</script>
