var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('users-table',{on:{"selection-change":_vm.onSelectionChange}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Title")]),_c('div',{style:({
                                color: _vm.isTooLong('title') ? 'red' : 'green'
                            })},[_vm._v(" Characters: "+_vm._s(_vm.getLength('title'))+"/"+_vm._s(_vm.titleMaxCharacters)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message.title),expression:"message.title"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.$v.message.title.$error
                            },attrs:{"id":"title","type":"text","maxlength":_vm.titleMaxCharacters},domProps:{"value":(_vm.message.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.message, "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('label',{attrs:{"for":"text"}},[_vm._v("Message")]),_c('div',{style:({
                                color: _vm.isTooLong('text') ? 'red' : 'green'
                            })},[_vm._v(" Characters: "+_vm._s(_vm.getLength('text'))+"/"+_vm._s(_vm.textMaxCharacters)+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message.text),expression:"message.text"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.$v.message.text.$error
                            },attrs:{"id":"text","type":"text","maxlength":_vm.textMaxCharacters},domProps:{"value":(_vm.message.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.message, "text", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12"},[_c('label',{attrs:{"for":"link"}},[_vm._v("Link")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message.link),expression:"message.link"}],staticClass:"form-control",attrs:{"id":"link","type":"link"},domProps:{"value":(_vm.message.link)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.message, "link", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-12 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-info waves-effect waves-light",attrs:{"data-animation":"fadein","data-plugin":"custommodal","data-overlaycolor":"#38414a"},on:{"click":_vm.sendNotificationsHandler}},[_vm._v(" Send Notifications ")])])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }